import React, { ChangeEvent, useId } from "react"
import { Link as RouterLink, useParams } from "react-router-dom"
import arrowBack from "../../../../assets/arrow_back.svg"
import Coordinates from "./components/Coordinates"
import PatientAdvice from "./components/PatientAdvice"
import Reason from "../SelectExercices/components/Reason"
import Questionnaire from "./components/Questionnaire/Questionnaire"
import { PATHOLOGIES_MOCK, ZONES_MOCK } from "../../newAppointment.mock"
import AButton from "../../../../components/AButton"
import SelectMainReason from "../SelectExercices/components/SelectMainReason"
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { ZonePathologieType } from "../../newAppointment.mock"
import { getPatient, PatientType } from "../../../../api/patient"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "AppContext"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import InternalNotes from "pages/NewAppointment/components/SelectReason/components/InternalNotes"
import { BabyMediaTag } from "api/media"
import BabyMediaTags from "pages/NewAppointment/components/SelectReason/components/BabyMediaTags/BabyMediaTags"
import SelectedBabyMediaTags from "pages/NewAppointment/components/SelectReason/components/SelectedBabyMediaTags/SelectedBabyMediaTags"

interface IProps {
  patient: PatientType | undefined
  setPatient: React.Dispatch<React.SetStateAction<PatientType | undefined>>
  setStep: React.Dispatch<React.SetStateAction<number>>
  advice: string | null
  setAdvice: React.Dispatch<React.SetStateAction<string | null>>
  pain: number | undefined
  setPain: React.Dispatch<React.SetStateAction<number | undefined>>
  stress: number | undefined
  setStress: React.Dispatch<React.SetStateAction<number | undefined>>
  frequency: number | undefined
  setFrequency: React.Dispatch<React.SetStateAction<number | undefined>>
  mobility: number | undefined
  setMobility: React.Dispatch<React.SetStateAction<number | undefined>>
  internalNote: string | null
  setInternalNote: React.Dispatch<React.SetStateAction<string | null>>
  secondaryInternalNote: string | null
  setSecondaryInternalNote: React.Dispatch<React.SetStateAction<string | null>>
  isBabySwitchOn: boolean
  setIsBabySwitchOn: React.Dispatch<React.SetStateAction<boolean>>
}

const SelectReason: React.FC<IProps> = ({
  patient,
  setPatient,
  setStep,
  advice,
  setAdvice,
  pain,
  setPain,
  stress,
  setStress,
  frequency,
  setFrequency,
  mobility,
  setMobility,
  internalNote,
  setInternalNote,
  secondaryInternalNote,
  setSecondaryInternalNote,
  isBabySwitchOn,
  setIsBabySwitchOn,
}) => {
  const { patientId } = useParams()
  const { currentScope } = useAppContext()
  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()
  const getPatientParams = { patientId, ...getCurrentScopeParams(currentScope) }
  const switchStatusId = useId()
  const {
    onOpen: onBabyPopoverOpen,
    onClose: onBabyPopoverClose,
    isOpen: isBabyPopoverOpen,
  } = useDisclosure()

  const onBabySwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsBabySwitchOn(e.target.checked)
    if (isBabySwitchOn) {
      onBabyPopoverClose()
      return
    }
    if (!isBabySwitchOn && !isBabyPopoverOpen) {
      onBabyPopoverOpen()
    }
  }

  useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(getPatientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error: any) =>
        retryPatientNoAccessRight(failureCount, error) &&
        retryCollaboratorUnauthorized(failureCount, error),
      onSuccess: (data) => {
        setPatient(data)
      },
      onError: (error: any) => {
        onCollaboratorUnauthorized(error)
        onPatientNoAccessRight(error)
      },
    }
  )

  // const isNextStepButtonDisabled =
  //   !isBabySwitchOn &&
  //   (pain === undefined ||
  //     stress === undefined ||
  //     mobility === undefined ||
  //     frequency === undefined)

  const handleNextStepForReason = () => {
    if (isBabySwitchOn) {
      setPain(0)
      setStress(0)
      setMobility(0)
      setFrequency(0)
      setStep(2)
    } else setStep(1)
  }

  if (!patient) return null
  return (
    <Box flex={1} p="16px">
      <Link
        as={RouterLink}
        to={`/patients/${patientId}`}
        p="8px"
        bgColor="primary.200"
        w="fit-content"
        display="block"
        rounded="9px"
      >
        <Image src={arrowBack} width="24px" height="24px" />
      </Link>
      <Flex
        direction={"row"}
        width="full"
        gap={4}
        alignItems={"start"}
        mb={6}
        justifyContent="space-between"
        mt={7}
      >
        <Coordinates patient={patient} />
        <Flex direction="column" gap="32px" maxW={640} w="full">
          <Flex w="full" justifyContent="space-between" alignItems="center">
            <Heading as="h1" fontSize={32} color="common.700">
              Nouvelle consultation
            </Heading>
            <Popover
              isOpen={isBabyPopoverOpen}
              onOpen={onBabyPopoverOpen}
              onClose={onBabyPopoverClose}
              placement="bottom"
            >
              <PopoverTrigger>
                <Switch
                  as="div"
                  id={switchStatusId}
                  whiteSpace="nowrap"
                  colorScheme="orange"
                  isChecked={isBabySwitchOn}
                  onChange={onBabySwitchChange}
                  height="fit-content"
                  bg="white"
                  py="12px"
                  px="16px"
                  display="flex"
                  alignItems="center"
                  borderRadius="xl"
                  fontWeight={"bold"}
                >
                  Nourrisson
                </Switch>
              </PopoverTrigger>
              <PopoverContent
                backgroundColor={"common.800"}
                px={2}
                py={4}
                maxW={240}
                mt={5}
                ml={44}
              >
                <PopoverBody>
                  <Flex direction={"column"} alignItems={"start"} gap={4}>
                    <Text color={"white"}>
                      La consultation nourrisson est activée : les vidéos sont
                      adaptées à ce type de consultation
                    </Text>
                    <Button
                      variant="link"
                      textDecoration={"underline"}
                      color={"white"}
                      onClick={onBabyPopoverClose}
                    >
                      C'est compris
                    </Button>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
          {!isBabySwitchOn && (
            <Questionnaire
              pain={pain}
              setPain={setPain}
              stress={stress}
              setStress={setStress}
              frequency={frequency}
              setFrequency={setFrequency}
              mobility={mobility}
              setMobility={setMobility}
            />
          )}
          <PatientAdvice advice={advice} setAdvice={setAdvice} />
        </Flex>
        <InternalNotes
          internalNote={internalNote}
          setInternalNote={setInternalNote}
          secondaryInternalNote={secondaryInternalNote}
          setSecondaryInternalNote={setSecondaryInternalNote}
        />
      </Flex>

      <Flex
        direction={"column"}
        align={"center"}
        justify="center"
        w={"full"}
        gap={2}
        fontFamily="Montserrat"
        bg="primary.200"
        py={6}
        position="fixed"
        bottom={0}
        left={0}
        zIndex={100}
      >
        <AButton
          text="Suivant"
          px={120}
          py={3}
          onClick={handleNextStepForReason}
          //isDisabled={isNextStepButtonDisabled}
        />
      </Flex>
    </Box>
  )
}

export default SelectReason
