import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import arrowBack from "../../assets/arrow_back.svg"
import React, { useEffect, useRef, useState } from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import AButton from "components/AButton"
import { Field, Form, Formik, FormikProps } from "formik"
import * as Yup from "yup"
import { getMessageTemplates, MessageTemplateType } from "api/messageTemplate"
import {
  createScheduledGroupMessage,
  getScheduledGroupMessages,
  ScheduledGroupMessageType,
} from "api/scheduledGroupMessage"
import { useAppContext } from "AppContext"
import { findPatients } from "api/patient"

const NewMessage = () => {
  const { currentScope, user } = useAppContext()
  const navigate = useNavigate()
  const toast = useToast()
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  const dateInputRef = useRef<HTMLInputElement>(null)

  const [messageTemplates, setMessageTemplates] = useState<
    MessageTemplateType[] | undefined
  >(undefined)

  const [messages, setMessages] = useState<
    ScheduledGroupMessageType[] | undefined
  >(undefined)

  const [sendNumber, setSendNumber] = useState<number | undefined>(undefined)

  const currentCenter =
    currentScope?.type === "center"
      ? user?.osteo.centers?.find((center) => center.id === currentScope.id)
      : null

  const fetchMessageTemplates = async () => {
    const fetchedTemplates = await getMessageTemplates()
    setMessageTemplates(fetchedTemplates)
  }

  const fetchMessages = async () => {
    const fetchedMessages = await getScheduledGroupMessages(
      currentScope?.type === "center" ? currentScope.id : undefined
    )
    setMessages(fetchedMessages)
  }

  const fetchSendNumber = async () => {
    const fetchedPatients = await findPatients(
      currentScope?.type === "center" ? { centerId: currentScope.id } : {}
    )
    setSendNumber(fetchedPatients.length)
  }

  const formikRef = useRef<FormikProps<{
    title: string
    sendDateTime: string
    text: string
  }> | null>(null)

  useEffect(() => {
    fetchMessageTemplates()
    fetchMessages()
    fetchSendNumber()
  }, [])

  return (
    <Flex p="16px 16px 128px 16px" direction="column">
      <Link href="/messages" w="fit-content" as={RouterLink} to="/messages">
        <Flex
          mb={6}
          cursor="pointer"
          p="8px"
          bgColor="primary.200"
          w="fit-content"
          display="block"
          rounded="9px"
        >
          <Image src={arrowBack} width="24px" height="24px" />
        </Flex>
      </Link>
      <Flex w="100%" justifyContent="center">
        <Flex direction="column" gap="40px">
          <Flex direction="column" gap="12px">
            <Heading as="h1" fontSize="32px">
              Nouveau message à tous mes patients
            </Heading>
            <Text fontSize="18px">
              Diffusez une information relative à votre cabinet
            </Text>
            <Flex
              bgColor="primary.200"
              p="12px"
              rounded="4px"
              gap="10px"
              fontSize="18px"
            >
              <InfoOutlineIcon />
              <Flex direction="column" gap={2}>
                {currentCenter && (
                  <Text lineHeight="100%">
                    Message envoyé aux patients du profil :{" "}
                    <b>{currentCenter?.name}</b>
                  </Text>
                )}
                <Text lineHeight="100%">
                  Nombre d’envois estimé : <b>{sendNumber}</b>
                </Text>
              </Flex>
            </Flex>
          </Flex>
          {messageTemplates && (
            <Flex direction="column" gap="12px">
              <Heading as="h2" fontSize="22px">
                Utiliser un modèle
              </Heading>
              <Flex gap="12px">
                {messageTemplates.map((template) => (
                  <AButton
                    key={template.id}
                    text={template.name}
                    fontSize="14px"
                    rounded="4px"
                    onClick={() => {
                      if (formikRef.current)
                        formikRef.current.setValues({
                          ...formikRef.current.values,
                          text: template.text,
                          title: template.subject,
                        })
                    }}
                  />
                ))}
              </Flex>
            </Flex>
          )}
          <Box width="100%">
            <Formik
              innerRef={formikRef}
              validationSchema={Yup.object().shape({
                title: Yup.string().min(2).required("Titre requis"),
                sendDateTime: Yup.date()
                  .required("Date et heure d'envoi requises")
                  .test(
                    "one-message-per-month-test",
                    "Vous ne pouvez envoyer qu'un message à vos patients par mois",
                    (value, context) => {
                      if (value) {
                        const oneMonthBefore = new Date(value)
                        oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1)

                        const oneMonthAfter = new Date(value)
                        oneMonthAfter.setMonth(oneMonthAfter.getMonth() + 1)

                        if (!messages || messages.length === 0) return true

                        const messageInRange = messages.find((message) => {
                          const messageDate = new Date(message.sendDateTime)
                          return (
                            messageDate >= oneMonthBefore &&
                            messageDate <= oneMonthAfter
                          )
                        })

                        return messageInRange === undefined
                      }
                      return false
                    }
                  ),
                text: Yup.string()
                  .max(1000, "Maximum 1000 caractères")
                  .required("Texte requis"),
              })}
              initialValues={{
                title: "",
                sendDateTime: "",
                text: "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                // Ajouter ici la logique pour envoyer le formulaire
                await createScheduledGroupMessage({
                  ...values,
                  center:
                    currentScope?.type === "center"
                      ? currentScope.id
                      : undefined,
                })
                setSubmitting(false)
                toast({
                  status: "success",
                  title: "Message ajouté avec succès",
                })
                navigate("/messages")
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Flex alignItems="flex-end" gap="16px">
                    <Field name="title">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={form.errors.title && form.touched.title}
                        >
                          <FormLabel fontWeight={700}>Titre *</FormLabel>
                          <Input
                            {...field}
                            placeholder="Titre"
                            bg="common.100"
                          />
                          <FormErrorMessage>
                            {form.errors.title}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="sendDateTime">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.sendDateTime &&
                            form.touched.sendDateTime
                          }
                        >
                          <Flex
                            justify={"space-between"}
                            align={"center"}
                            mb={2}
                          >
                            <FormLabel fontWeight={700} mb={0}>
                              Date et heure d'envoi *
                            </FormLabel>
                            <AButton
                              text="Fermer le calendrier"
                              px={2}
                              py={1}
                              fontSize="12px"
                              fontFamily="Montserrat"
                              variant="custom"
                              borderRadius={"8px"}
                              color="black"
                              border="none"
                              bg="white"
                              cursor="pointer"
                              onClick={() => {
                                if (dateInputRef.current)
                                  dateInputRef.current.blur()
                              }}
                              isDisabled={!isCalendarOpen}
                            />
                          </Flex>
                          <Input
                            {...field}
                            type="datetime-local"
                            bg="common.100"
                            ref={dateInputRef}
                            onFocus={() => setIsCalendarOpen(true)}
                            onBlur={() => setIsCalendarOpen(false)}
                            placeholder="YYYY-MM-DD HH:MM"
                          />
                          <FormErrorMessage>
                            {form.errors.sendDateTime}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>

                  <Field name="text">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.text && form.touched.text}
                      >
                        <FormLabel mt={6} fontWeight={700}>
                          Texte *
                        </FormLabel>
                        <Textarea
                          {...field}
                          bg="common.100"
                          placeholder="Votre message"
                          height="223px"
                        />
                        <FormErrorMessage>{form.errors.text}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    w="full"
                    gap={2}
                    fontFamily="Montserrat"
                    bg="primary.200"
                    py={6}
                    position="fixed"
                    bottom={0}
                    left={0}
                    zIndex={90}
                  >
                    <AButton
                      text="Programmer"
                      px={120}
                      py={3}
                      fontFamily="Montserrat"
                      type="submit"
                      isLoading={isSubmitting}
                    />
                  </Flex>
                </Form>
              )}
            </Formik>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default NewMessage
