import EditSelectExercices from "pages/EditAppointment/components/EditSelectExercices"
import { useNavigate, useParams } from "react-router-dom"
import { SelectedExerciceType } from "api/appointmentReason"
import { updateAppointment } from "api/appointments"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Box } from "@chakra-ui/react"
import useToast from "hooks/useToast"
import React, { useEffect, useState } from "react"
import { getPatient, PatientAppointmentType, PatientType } from "api/patient"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { useAppContext } from "AppContext"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"

const EditAppointment = () => {
  const { patientId, appointmentId } = useParams()
  const { currentScope } = useAppContext()
  const updateAppointmentMutation = useMutation(updateAppointment)
  const toast = useToast()
  const navigate = useNavigate()
  const [selectedExercices, setSelectedExercices] = useState<
    SelectedExerciceType[]
  >([])
  const getPatientParams = {
    patientId,
    ...getCurrentScopeParams(currentScope),
  }
  const [appointment, setAppointment] =
    React.useState<PatientAppointmentType | null>(null)
  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()

  useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(getPatientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error: any) =>
        retryPatientNoAccessRight(failureCount, error) &&
        retryCollaboratorUnauthorized(failureCount, error),
      onSuccess: (data) => {
        setAppointment(
          !appointmentId
            ? null
            : data?.appointments?.find(
                (appointment) => appointment.id === parseInt(appointmentId)
              ) ?? null
        )
      },
      onError: (error: any) => {
        onPatientNoAccessRight(error)
        onCollaboratorUnauthorized(error)
      },
    }
  )

  useEffect(() => {
    if (appointment) {
      setSelectedExercices(
        appointment.trainingPlaylist.exercices.map((exercice) => {
          const groupId = exercice.osteoSelection.group[0].id
          const { osteoSelection, duration } = exercice
          return {
            ...osteoSelection,
            duration,
            videoGroup: groupId,
          }
        })
      )
    }
  }, [appointment])

  const onBack = () => {
    navigate(`/patients/${patientId}/appointments/${appointmentId}`)
  }

  const onNext = async () => {
    if (appointment && appointment.id) {
      await updateAppointmentMutation.mutateAsync(
        {
          id: appointment.id.toString(),
          data: {
            videoExercices: selectedExercices.map((exercice) => ({
              duration: exercice.duration,
              videoGroup: exercice.videoGroup,
              osteoSelection: exercice.id,
            })),
          },
        },
        {
          onSuccess: () => {
            toast({
              status: "success",
              title: "Modifications enregistrées",
            })
          },
          onError: () => {
            toast({
              status: "error",
              title: "Une erreur s'est produite",
            })
          },
        }
      )
      navigate(`/patients/${patientId}/appointments/${appointmentId}`)
    }
  }
  if (!appointment) return null

  if (appointment.trainingPlaylist.reasons?.length === 0)
    return (
      <EditSelectExercices
        onNext={onNext}
        onBack={onBack}
        selectedExercices={selectedExercices}
        setSelectedExercices={setSelectedExercices}
      />
    )
  else
    return (
      <EditSelectExercices
        onNext={onNext}
        onBack={onBack}
        reasons={appointment.trainingPlaylist.reasons}
        selectedExercices={selectedExercices}
        setSelectedExercices={setSelectedExercices}
      />
    )
}
export default EditAppointment
