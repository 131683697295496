import React, { useEffect, useState } from "react"
import { VideoType } from "api/appointmentReason"
import { SelectedExerciceType } from "api/appointmentReason"
import AExerciceCardBase from "components/AExerciceCard/Base"

interface IProps {
  video: VideoType
  videoGroupId: number
  selectedExercices?: SelectedExerciceType[]
  setSelectedExercices?: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  title?: string
  videosCount?: number
}

const AExerciceCard: React.FC<IProps> = ({
  video,
  videoGroupId,
  selectedExercices,
  setSelectedExercices,
  title,
  videosCount,
}) => {
  const [isSelecting, setIsSelecting] = useState(false)

  const isSelected = !selectedExercices
    ? false
    : selectedExercices.some((exercice) => exercice.id === video.id)

  const onClickSelection = isSelected ? () => null : () => setIsSelecting(true)

  const onAdd = (value: number) => {
    if (!value || !setSelectedExercices) return
    setSelectedExercices((prev) => [
      ...prev,
      {
        ...video,
        duration: value,
        videoGroup: videoGroupId,
        defaultDuration: video.defaultDuration,
      },
    ])
  }
  useEffect(() => {
    setIsSelecting(false)
  }, [isSelected])
  return (
    <AExerciceCardBase
      video={video}
      videoGroupId={videoGroupId}
      title={title}
      isSelecting={isSelecting}
      isSelected={isSelected}
      isDisabled={isSelected}
      onClick={onClickSelection}
      onAdd={onAdd}
      videosCount={videosCount}
      isAddCard={selectedExercices !== undefined}
    />
  )
}

export default AExerciceCard
