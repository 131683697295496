import {
  AppointmentReasonType,
  SelectedExerciceType,
  VideoType,
} from "api/appointmentReason"
import axiosInstance from "api/axiosInstance"

export type TrainingVideoPrograms = {
  id: number
  trainingVideo: VideoType
  duration: number
}

export type ProgramType = {
  id: number
  title: string
  appointmentReasons: AppointmentReasonType[]
  level: number
  trainingVideoPrograms: TrainingVideoPrograms[]
}

export type CreateProgramType = {
  program: {
    title: string
    appointmentReasons: number[]
    level: number
  }
  videoPrograms: SelectedExerciceType[]
}

export const getMyPrograms = async () => {
  const { data } = await axiosInstance.get<ProgramType[]>(
    `/programs/getMyPrograms`
  )
  return data
}

export const createProgram = async (data: CreateProgramType) => {
  const response = await axiosInstance.post<ProgramType[]>(
    `/programs/createProgram`,
    { ...data }
  )
  return response.data
}

export const getCommonPrograms = async () => {
  const { data } = await axiosInstance.get<ProgramType[]>(`/common-program/all`)
  return data
}
