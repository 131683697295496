import { useEffect, useMemo } from "react"
import AHeader from "./components/AHeader"
import ARoutes from "./ARoutes"
import { Box, Container, Flex } from "@chakra-ui/react"
import { useAppContext } from "./AppContext"
import { useLocation, useSearchParams } from "react-router-dom"
import AFooter from "./components/AFooter"
import { pageView } from "./utils/facebookPixelEvents"
import userHasNoPersonalAccount from "utils/user/userHasNoPersonalAccount"
import userHasAcademiesOrCenters from "utils/user/userHasAcademiesOrCenters"
import { useCookies } from "react-cookie"
import useHasAcceptedCGS from "hooks/useHasAcceptedCGS"

const Layout = () => {
  const { user, jwt } = useAppContext()
  const [searchParams] = useSearchParams()
  const [cookies, setCookie, removeCookies] = useCookies(["partner"])
  const [affiliationCookies, setAffiliationCookies, removeAffiliationCookies] =
    useCookies(["affiliation"])
  const hasAcceptedCGS = useHasAcceptedCGS()

  const partner = searchParams.get("partner")
  const affiliation = searchParams.get("affiliation")
  useEffect(() => {
    if (partner) {
      // Set a session cookie to remember the partner
      setCookie("partner", partner, { path: "/" })
    }
  }, [partner, removeCookies, setCookie])

  useEffect(() => {
    if (affiliation) {
      setAffiliationCookies("affiliation", affiliation, { path: "/" })
    }
  }, [affiliation, removeAffiliationCookies, setAffiliationCookies])

  const location = useLocation()
  const hasHeader = useMemo(() => {
    if (location.pathname === "/invitation") return false
    if (location.pathname === "/forum") return false
    if (location.pathname === "/cgs" && !hasAcceptedCGS) return false
    if (location.pathname.includes("/appointments/new")) return false
    if (location.pathname.includes("/patients/new")) return false
    if (location.pathname.includes("/programs/new")) return false
    if (location.pathname.includes("/messages/new")) return false
    if (location.pathname.includes("/messages/edit")) return false
    return (
      jwt &&
      user &&
      user.passwordInitiated === true &&
      (!userHasNoPersonalAccount(user) || userHasAcademiesOrCenters(user))
    )
  }, [user, jwt, location])

  useEffect(() => {
    pageView()
  }, [location])

  return (
    <Flex>
      {hasHeader && <AHeader />}
      <Box w="full">
        <Box
          bg="primary.100"
          minH={"100vh"}
          px={hasHeader ? 6 : 0}
          py={hasHeader ? 8 : 0}
        >
          <Container maxW="1920px" px={hasHeader ? 4 : 0}>
            <ARoutes />
          </Container>
        </Box>
        {hasHeader && <AFooter />}
      </Box>
    </Flex>
  )
}

export default Layout
