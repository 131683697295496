import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react"
import React, { useState } from "react"
import {
  SelectedExerciceType,
  TrainingVideoGroupsType,
} from "api/appointmentReason"
import AExerciceCard from "components/AExerciceCard/AExerciceCard"
import { MdFavorite, MdFavoriteBorder } from "react-icons/md"
import { like } from "api/trainingVideoGroup"
import { ReasonType } from "api/patient"

interface IProps {
  videoGroup: TrainingVideoGroupsType
  selectedExercices?: SelectedExerciceType[]
  setSelectedExercices?: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  fetchDataForReason?: (reason: ReasonType | null) => Promise<void>
  reason?: ReasonType | null
}

const VideoGroup: React.FC<IProps> = ({
  videoGroup,
  selectedExercices,
  setSelectedExercices,
  fetchDataForReason,
  reason,
}) => {
  const authorizedVideos = videoGroup.videos.filter(
    ({ limitedMode }) => limitedMode === false
  )

  const [isLiked, setIsLiked] = useState(
    videoGroup.osteosLiking && videoGroup.osteosLiking.length > 0
  )

  const isSolo = authorizedVideos.length === 1

  const likeVideoGroup = async () => {
    const likedVideoGroup = await like({ id: videoGroup.id, like: !isLiked })
    if (likedVideoGroup) {
      setIsLiked(!isLiked)
      if (fetchDataForReason !== undefined) {
        await fetchDataForReason(reason || null)
      }
    }
  }

  if (authorizedVideos.length === 0) {
    return null
  }

  return (
    <Box
      bg="white"
      borderRadius="10px"
      p={2}
      w={isSolo ? "calc(33% - 9px)" : "100%"}
    >
      <Flex flexDir={"column"} h="full">
        <Flex
          flex={1}
          justifyContent="space-between"
          alignItems="center"
          pb={2}
        >
          <Text fontWeight={700} fontSize={14}>
            {videoGroup.title}
          </Text>

          <Button
            p={0}
            bg="none"
            _hover={{ bg: "none", opacity: 0.7 }}
            onClick={() => likeVideoGroup()}
            w={"20px"}
            h={"20px"}
          >
            {isLiked ? (
              <Icon
                w={"20px"}
                h={"20px"}
                color="secondary.900"
                aria-label="Enlever des favoris"
                as={MdFavorite}
              />
            ) : (
              <Icon
                w={"20px"}
                h={"20px"}
                color="secondary.900"
                aria-label="Ajouter aux favoris"
                as={MdFavoriteBorder}
              />
            )}
          </Button>
        </Flex>
        <Flex gap={4}>
          {authorizedVideos.map((video) => (
            <AExerciceCard
              key={`video_${video.id}`}
              video={video}
              videoGroupId={videoGroup.id}
              selectedExercices={selectedExercices}
              setSelectedExercices={setSelectedExercices}
              videosCount={authorizedVideos.length}
            />
          ))}
        </Flex>
      </Flex>
    </Box>
  )
}

export default VideoGroup
