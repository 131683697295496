import { AddIcon } from "@chakra-ui/icons"
import { Flex, Heading, Link } from "@chakra-ui/react"
import AButton from "components/AButton"
import ExercicesLists from "pages/Exercices/components/ExercicesLists/ExercicesLists"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"
import React, { useState } from "react"
import { Link as RouterLink } from "react-router-dom"

const Exercices = () => {
  const [reasonType, setReasonType] = useState<"zones" | "pathologies" | null>(
    "zones"
  )
  const [reason, setReason] = useState<ZonePathologieType>()

  return (
    <Flex direction="column" gap="32px">
      <Flex justifyContent="space-between">
        <Heading as="h1">Exercices</Heading>
        <Flex gap="10px">
          <Link as={RouterLink} to="/programs/new">
            <AButton
              text="Nouveau programme"
              variant="tertiary"
              leftIcon={<AddIcon />}
            />
          </Link>
          <Link as={RouterLink} to="/patients/new">
            {/* <AButton
              text="Nouveau patient"
              variant="destructive"
              leftIcon={<AddIcon />}
            /> */}
          </Link>
        </Flex>
      </Flex>
      <Flex>
        <ExercicesLists
          reason={reason}
          setReason={setReason}
          reasonType={reasonType}
          setReasonType={setReasonType}
        />
      </Flex>
    </Flex>
  )
}

export default Exercices
