import { Box, Flex, Spinner } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { curryIncludesInsensitive } from "utils/includesInsensitive"
import {
  getAppointmentReason,
  SelectedExerciceType,
  TrainingVideoGroupsType,
  VideoGroupCategoryType,
  VideoType,
} from "api/appointmentReason"
import Header from "pages/NewAppointment/components/SelectExercices/components/Header/Header"
import SelectedExercices from "pages/NewAppointment/components/SelectExercices/components/SelectedExercices/SelectedExercices"
import SuggestedProgramme from "pages/NewAppointment/components/SelectExercices/components/SuggestedProgramme/SuggestedProgramme"
import { useQuery } from "@tanstack/react-query"
import { ReasonType } from "api/patient"
import {
  ZonePathologieType,
  ZONES_MOCK,
} from "pages/NewAppointment/newAppointment.mock"
import FilterExercices from "pages/Exercices/components/ExercicesLists/components/FilterExercices/FilterExercices"
import VideoGroup from "pages/Exercices/components/ExercicesLists/components/VideoGroup/VideoGroup"
import { niceDisplayVideoGroup } from "utils/niceDisplayVideoGroup"

// HELPERS
const sortByTimeUsed = (
  a: TrainingVideoGroupsType,
  b: TrainingVideoGroupsType
) => {
  if (a.timeUsed < b.timeUsed) {
    return 1
  }
  if (a.timeUsed > b.timeUsed) {
    return -1
  }
  return 0
}

// COMPONENTS
interface IProps {
  onBack: () => void
  onNext: () => void
  reasons?: ReasonType[]
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
}

const EditSelectExercices: React.FC<IProps> = ({
  onBack,
  onNext,
  reasons,
  selectedExercices,
  setSelectedExercices,
}) => {
  const [currentReason, setCurrentReason] = useState<ReasonType | null>(
    reasons === undefined ? null : reasons[0]
  )
  const [search, setSearch] = useState("")
  const [suggestedProgramme, setSuggestedProgramme] = useState<VideoType[]>([])
  const [videoGroups, setVideoGroups] = useState<TrainingVideoGroupsType[]>([])
  const [categories, setCategories] = useState<VideoGroupCategoryType[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null)

  const [restrictionFilter, setRestrictionFilter] = useState<string | null>(
    null
  )
  const restrictions = videoGroups.reduce(
    (acc, { restrictions }) => [
      ...acc,
      ...(restrictions ?? []).reduce(
        (restrictionsAcc, { name }) =>
          acc.includes(name) ? restrictionsAcc : [...restrictionsAcc, name],
        [] as string[]
      ),
    ],
    [] as string[]
  )

  const fetchDataForReason = async (reason: ReasonType | null) => {
    const { data } = await getAppointmentReason(reason?.id ?? -1)
    setSuggestedProgramme(data.suggestedProgramme)
    setVideoGroups(data.trainingVideoGroups)
  }

  useEffect(() => {
    fetchDataForReason(currentReason)
  }, [currentReason])

  useEffect(() => {
    const listOfCategories = videoGroups
      .map((videoGroup) => videoGroup.category)
      .filter((elt) => elt !== null)

    const uniqueCategories = listOfCategories.filter(
      (category, index) => listOfCategories.indexOf(category) === index
    )

    setCategories(uniqueCategories)
  }, [videoGroups])

  useEffect(() => {
    // reset filters
    setCategoryFilter(null)
    setSearch("")
  }, [currentReason])

  const filterWithCategoriesRestrictionsAndSearch = (
    list: TrainingVideoGroupsType[]
  ) => {
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = list
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (categoryFilter !== null) {
      filteredList = filteredList.filter(
        ({ category }) => category === categoryFilter
      )
    }
    if (restrictionFilter !== null) {
      filteredList = filteredList.filter(({ restrictions }) =>
        restrictions.some(({ name }) => name === restrictionFilter)
      )
    }
    return filteredList
  }

  const sortedFilteredVideoGroups = niceDisplayVideoGroup(
    filterWithCategoriesRestrictionsAndSearch(videoGroups.sort(sortByTimeUsed))
  )

  return (
    <Box position="relative">
      {reasons !== undefined && currentReason !== null ? (
        <Header
          onBack={onBack}
          reasons={reasons}
          currentReason={currentReason}
          setCurrentReason={setCurrentReason}
        />
      ) : (
        <Header
          onBack={onBack}
          currentReason={currentReason}
          setCurrentReason={setCurrentReason}
        />
      )}

      <Flex gap="74px">
        <Box flex={1}>
          {/* {reasons !== undefined && currentReason !== null && (
            <>
              {suggestedProgramme.length > 0 && (
                <SuggestedProgramme
                  videos={suggestedProgramme}
                  selectedExercices={selectedExercices}
                  setSelectedExercices={setSelectedExercices}
                />
              )}
            </>
          )} */}
          <Box mb={6}>
            <FilterExercices
              categories={categories}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              setSearch={setSearch}
              restrictions={restrictions}
              restrictionFilter={restrictionFilter}
              setRestrictionFilter={setRestrictionFilter}
            />
          </Box>
          <Flex justifyContent="flex-start" flexWrap="wrap" gap={4}>
            {sortedFilteredVideoGroups.map((videoGroup, index) => (
              <VideoGroup
                key={`group_${videoGroup.id}__${index}`}
                videoGroup={videoGroup}
                selectedExercices={selectedExercices}
                setSelectedExercices={setSelectedExercices}
              />
            ))}
          </Flex>
        </Box>
        {selectedExercices && (
          <SelectedExercices
            selectedExercices={selectedExercices}
            setSelectedExercices={setSelectedExercices}
            onNext={onNext}
            isEditingAppointment={true}
          />
        )}
      </Flex>
    </Box>
  )
}

export default EditSelectExercices
