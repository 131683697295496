import { AddIcon, MinusIcon, ViewIcon } from "@chakra-ui/icons"
import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useNumberInput,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { VideoType } from "api/appointmentReason"
import AButton from "../AButton"
import { MdFavorite, MdFavoriteBorder } from "react-icons/md"

interface IProps {
  video: VideoType
  videoGroupId: number
  title?: string
  isSelecting?: boolean
  isSelected?: boolean
  isDisabled?: boolean
  onClick: () => void
  onAdd?: (value: number) => void
  videosCount?: number
  isAddCard?: boolean
}

const AExerciceCardBase: React.FC<IProps> = ({
  video,
  videoGroupId,
  title,
  isSelecting,
  isSelected,
  isDisabled,
  onClick,
  onAdd,
  videosCount,
  isAddCard = true,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
    valueAsNumber,
  } = useNumberInput({
    step: 5,
    defaultValue: video.defaultDuration || 30,
    min: video.defaultDuration || 0,
    max: 300,
    isDisabled,
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  return (
    <Box
      w={
        videosCount === 1
          ? "100%"
          : videosCount === 2
          ? "calc(50% - 6px)"
          : "calc(33% - 6px)"
      }
    >
      <Box
        bg="white"
        w="100%"
        overflow="hidden"
        cursor={isDisabled ? "not-allowed" : undefined}
        opacity={isDisabled ? 0.4 : 1}
      >
        <AspectRatio ratio={videosCount === 3 ? 16 / 9 : 2.37}>
          <Box
            borderRadius={9}
            pos="relative"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <Image
              alt={video.preview.alternativeText}
              src={video.preview.formats.small.url}
              w="100%"
              h="100%"
              objectFit="cover"
            />
            {isHovering && !isDisabled && (
              <Flex
                bg="rgba(17, 23, 45, 0.5)"
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                alignItems="center"
                justifyContent="center"
              >
                <AButton
                  text="Aperçu"
                  variant="tertiary"
                  fontSize={12}
                  leftIcon={<ViewIcon />}
                  onClick={isDisabled ? () => null : onOpen}
                />
              </Flex>
            )}
          </Box>
        </AspectRatio>
        {isSelecting && isAddCard ? (
          <Flex
            py={3}
            px={2}
            alignItems="center"
            justifyContent="center"
            gap={2}
            flexWrap="wrap"
          >
            <Flex alignItems="center" fontSize={12}>
              <Text fontWeight={700}>Durée</Text>
              <Text ml={1}>(sec)</Text>
            </Flex>
            <Flex alignItems="center">
              <MinusIcon
                {...dec}
                w={3}
                color="primary.500"
                cursor={isDisabled ? "not-allowed" : "pointer"}
              >
                -
              </MinusIcon>
              <Input
                {...input}
                bg="common.100"
                w="60px"
                h="25px"
                p={2}
                textAlign="center"
                mx={2}
                fontWeight={700}
                fontSize={14}
              />
              <AddIcon
                {...inc}
                w={3}
                color="primary.500"
                cursor={isDisabled ? "not-allowed" : "pointer"}
              >
                -
              </AddIcon>
            </Flex>
            <AButton
              text="Ajouter"
              fontSize={11}
              fontWeight={700}
              px={2}
              py={2}
              height="fit-content"
              isDisabled={isDisabled}
              onClick={onAdd ? () => onAdd(valueAsNumber) : undefined}
            />
          </Flex>
        ) : (
          <Flex
            pt={2}
            alignItems="center"
            onClick={onClick}
            cursor="pointer"
            as="button"
          >
            {isAddCard && (
              <AddIcon w={3} h={3} color="tertiary.500" ml={0} mr={"8px"} />
            )}
            <Box>
              {title ? (
                <div>
                  <Text fontSize={14} fontWeight={700} color="tertiary.500">
                    {title}
                  </Text>
                  <Text fontSize={12}>Niveau {video.level}</Text>
                </div>
              ) : (
                <Text fontSize={14} fontWeight={700} color="tertiary.500">
                  Niveau {video.level}
                </Text>
              )}
            </Box>
          </Flex>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent maxW="724">
          <Box
            pos="relative"
            borderRadius={9}
            bg="white"
            w={724}
            h={469}
            py="32px"
            px="54px"
          >
            <ModalCloseButton aria-label="Fermer" />
            <Heading fontSize={22} fontWeight="bold" textAlign="center" mb={7}>
              {title
                ? title
                : video.group.find((g) => g.id === videoGroupId)?.title}
            </Heading>
            <video
              src={video.fullVideo?.url}
              controls
              onContextMenu={(e) => e.preventDefault()}
              controlsList="nodownload"
            ></video>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default AExerciceCardBase
