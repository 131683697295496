import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  MinusIcon,
  ViewIcon,
} from "@chakra-ui/icons"
import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useNumberInput,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { SelectedExerciceType } from "api/appointmentReason"
import AButton from "components/AButton"

interface IProps {
  selectedExercice: SelectedExerciceType
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  position: number
  size: number
  isDisabled?: boolean
}

const SelectedExerciceCard: React.FC<IProps> = ({
  selectedExercice,
  setSelectedExercices,
  position,
  size,
  isDisabled = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 5,
      defaultValue: selectedExercice.duration,
      min: selectedExercice.defaultDuration || 0,
      max: 300,
      isDisabled,
    })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  const value = input["aria-valuenow"]
  useEffect(() => {
    if (!value) return

    setSelectedExercices((prev) => {
      return prev.map((exercice) => {
        if (exercice.id === selectedExercice.id) {
          return { ...exercice, duration: value }
        }
        return exercice
      })
    })
  }, [value, selectedExercice.id, setSelectedExercices])

  const handleRemoveExercice = () => {
    if (!isDisabled) {
      setSelectedExercices((prev) => {
        return prev.filter((exercice) => exercice.id !== selectedExercice.id)
      })
    }
  }

  const increasePosition = () => {
    if (!isDisabled) {
      const pos = position - 1
      setSelectedExercices((prev) => {
        const newExercices = [...prev]
        const exercice = newExercices[pos]
        newExercices[pos] = newExercices[pos + 1]
        newExercices[pos + 1] = exercice
        return newExercices
      })
    }
  }

  const decreasePosition = () => {
    if (!isDisabled) {
      const pos = position - 1
      setSelectedExercices((prev) => {
        const newExercices = [...prev]
        const exercice = newExercices[pos]
        newExercices[pos] = newExercices[pos - 1]
        newExercices[pos - 1] = exercice
        return newExercices
      })
    }
  }

  const [isHoveringPreview, setIsHoveringPreview] = useState(false)
  const [isHoveringAll, setIsHoveringAll] = useState(false)

  return (
    <Flex alignItems="center" as="li" gap={2}>
      <Flex
        flexDir="column"
        alignItems="center"
        bg="white"
        borderRadius={9}
        py={2.5}
        px={1}
      >
        {position !== 1 && (
          <ChevronUpIcon
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={decreasePosition}
          />
        )}
        <Text>{position}</Text>
        {position !== size && (
          <ChevronDownIcon
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={increasePosition}
          />
        )}
      </Flex>
      <Flex
        bg="white"
        py={2}
        px={3}
        borderRadius={9}
        pos="relative"
        flex={1}
        alignItems="center"
        gap="10px"
        onMouseEnter={() => setIsHoveringAll(true)}
        onMouseLeave={() => setIsHoveringAll(false)}
      >
        <Box
          pos="relative"
          onMouseEnter={() => setIsHoveringPreview(true)}
          onMouseLeave={() => setIsHoveringPreview(false)}
          rounded="8px"
          w="64px"
          h="64px"
          overflow="hidden"
        >
          <Image
            alt={selectedExercice.preview.alternativeText}
            src={selectedExercice.preview.formats.small.url}
            w="64px"
            h="64px"
            objectFit="cover"
          />
          {isHoveringPreview && !isDisabled && (
            <Flex
              bg="rgba(17, 23, 45, 0.5)"
              pos="absolute"
              top={0}
              left={0}
              w="100%"
              h="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Flex
                cursor={isDisabled ? "not-allowed" : "pointer"}
                borderRadius="50%"
                border="1px solid"
                borderColor="primary.500"
                w={34}
                h={34}
                justifyContent="center"
                alignItems="center"
                onClick={isDisabled ? undefined : onOpen}
                color="primary.500"
                bg="white"
                _hover={{
                  bg: "primary.100",
                }}
              >
                <ViewIcon />
              </Flex>
            </Flex>
          )}
        </Box>
        <Flex flexDirection="column" w="full" flex={1}>
          <Text color="tertiary.500" fontSize={14} fontWeight={700}>
            {
              selectedExercice.group.find(
                (currentGroup) =>
                  currentGroup.id === selectedExercice.videoGroup
              )?.title
            }
          </Text>
          <Text fontSize={12}>{`Niveau ${selectedExercice.level}`}</Text>
          <Flex alignItems="end" w="full" justifyContent="end">
            <Flex alignItems="center">
              <MinusIcon
                {...dec}
                w={3}
                color="primary.500"
                cursor={isDisabled ? "not-allowed" : "pointer"}
              >
                -
              </MinusIcon>
              <Input
                {...input}
                bg="common.100"
                w="60px"
                h="25px"
                p={2}
                textAlign="center"
                mx={2}
                fontWeight={700}
                fontSize={14}
                cursor={isDisabled ? "not-allowed" : "auto"}
              />
              <AddIcon
                {...inc}
                w={3}
                color="primary.500"
                cursor={isDisabled ? "not-allowed" : "pointer"}
              >
                -
              </AddIcon>
            </Flex>
          </Flex>
        </Flex>
        {isHoveringAll && (
          <Flex pos="absolute" top={0} right={0} alignItems="center">
            <IconButton
              cursor={isDisabled ? "not-allowed" : "pointer"}
              onClick={handleRemoveExercice}
              _hover={{ color: "danger.500" }}
              aria-label="Supprimer la vidéo"
              background="none"
              icon={<DeleteIcon boxSize={3.5} />}
              color="common.400"
            />
          </Flex>
        )}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent maxW="724">
          <Box
            pos="relative"
            borderRadius={9}
            bg="white"
            w={724}
            h={469}
            py="32px"
            px="54px"
          >
            <ModalCloseButton aria-label="Fermer" />
            <Heading fontSize={22} fontWeight="bold" textAlign="center" mb={7}>
              {selectedExercice.group[0].title}
            </Heading>
            <video
              src={selectedExercice.fullVideo?.url}
              controls
              onContextMenu={(e) => e.preventDefault()}
              controlsList="nodownload"
            ></video>
          </Box>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default SelectedExerciceCard
