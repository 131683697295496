import { Box, Flex, Heading, Image, Spinner, Text } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { curryIncludesInsensitive } from "utils/includesInsensitive"
import {
  getAppointmentReason,
  SelectedExerciceType,
  TrainingVideoGroupsType,
  VideoGroupCategoryType,
  VideoType,
} from "api/appointmentReason"
import {
  PATHOLOGIES_MOCK,
  ZonePathologieType,
  ZONES_MOCK,
} from "pages/NewAppointment/newAppointment.mock"
import { ReasonType } from "api/patient"
import Reason from "pages/NewAppointment/components/SelectExercices/components/Reason"
import ZonesPathologies from "pages/Exercices/components/ExercicesLists/components/ZonesPathologies/ZonesPathologies"
import TabExercices from "pages/Exercices/components/ExercicesLists/components/TabExecices/TabExercices"
import VideoGroup from "pages/Exercices/components/ExercicesLists/components/VideoGroup/VideoGroup"
import SelectedExercices from "pages/NewAppointment/components/SelectExercices/components/SelectedExercices/SelectedExercices"
import FilterExercices from "pages/Exercices/components/ExercicesLists/components/FilterExercices/FilterExercices"

const sortByTimeUsed = (
  a: TrainingVideoGroupsType,
  b: TrainingVideoGroupsType
) => {
  if (a.timeUsed < b.timeUsed) {
    return 1
  }
  if (a.timeUsed > b.timeUsed) {
    return -1
  }
  return 0
}

export const VIDEOS_TAB = 0
export const PROGRAMME_TAB = 1
export const FAVORIS_TAB = 2

// COMPONENTS
interface IProps {
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  reasonType: "zones" | "pathologies" | null
  setReasonType: (reason: "zones" | "pathologies" | null) => void
  reason: ZonePathologieType | undefined
  setReason: React.Dispatch<
    React.SetStateAction<ZonePathologieType | undefined>
  >
}

const SelectProgramExercices: React.FC<IProps> = ({
  selectedExercices,
  setSelectedExercices,
  reason,
  setReason,
  reasonType,
  setReasonType,
}) => {
  const [search, setSearch] = useState("")
  const [videoGroups, setVideoGroups] = useState<TrainingVideoGroupsType[]>([])
  const [categories, setCategories] = useState<VideoGroupCategoryType[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null)

  const [restrictionFilter, setRestrictionFilter] = useState<string | null>(
    null
  )

  const restrictions = videoGroups.reduce(
    (acc, { restrictions }) => [
      ...acc,
      ...(restrictions ?? []).reduce(
        (restrictionsAcc, { name }) =>
          acc.includes(name) ? restrictionsAcc : [...restrictionsAcc, name],
        [] as string[]
      ),
    ],
    [] as string[]
  )

  const fetchDataForReason = async (reason: ReasonType | null) => {
    const { data } = await getAppointmentReason(reason?.id ?? -1)
    setVideoGroups(data.trainingVideoGroups)
  }

  useEffect(() => {
    fetchDataForReason(reason ?? null)
  }, [reason])

  useEffect(() => {
    const listOfCategories = videoGroups
      .map((videoGroup) => videoGroup.category)
      .filter((elt) => elt !== null)

    const uniqueCategories = listOfCategories.filter(
      (category, index) => listOfCategories.indexOf(category) === index
    )

    setCategories(uniqueCategories)
  }, [videoGroups])

  useEffect(() => {
    // reset filters
    setCategoryFilter(null)
    setSearch("")
  }, [reason])

  const filterWithCategoriesRestrictionsAndSearch = (
    list: TrainingVideoGroupsType[]
  ) => {
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = list
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (categoryFilter !== null) {
      filteredList = filteredList.filter(
        ({ category }) => category === categoryFilter
      )
    }
    if (restrictionFilter !== null) {
      filteredList = filteredList.filter(({ restrictions }) =>
        restrictions.some(({ name }) => name === restrictionFilter)
      )
    }
    return filteredList
  }

  const niceDisplayVideoGroup = (groups: TrainingVideoGroupsType[]) => {
    let temp: null | TrainingVideoGroupsType = null
    const sortedGroups: TrainingVideoGroupsType[] = []
    groups.map((group) => {
      const videosCount = group.videos.filter(
        ({ limitedMode }) => limitedMode === false
      ).length
      if (videosCount > 1) return sortedGroups.push({ ...group })
      if (temp !== null) {
        sortedGroups.push({ ...temp }, { ...group })
        temp = null
        return null
      }
      temp = group
    })
    return sortedGroups
  }

  const [sortedFilteredVideoGroups, setSortedFilteredVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])

  const [selectedTab, setSelectedTab] = useState(VIDEOS_TAB)

  useEffect(() => {
    setSortedFilteredVideoGroups(
      niceDisplayVideoGroup(
        filterWithCategoriesRestrictionsAndSearch(
          videoGroups.sort(sortByTimeUsed)
        )
      )
    )
  }, [videoGroups, categoryFilter, restrictionFilter, search])

  const allReasons: ZonePathologieType[] = [
    {
      id: -1,
      name: "Toutes les zones",
      code: "ALL",
      left: "0px",
      top: "0px",
      schema: 1,
    },
    ...ZONES_MOCK,
  ]

  return (
    <Box p="16px" width="full">
      <Flex>
        <Flex direction="column" pos="sticky" top={4} h="fit-content">
          <Reason
            reason={reasonType}
            setReason={setReasonType}
            isBabySwitchOn={false}
          />
          <Flex alignItems={"center"}>
            <Flex justifyContent="center" w="full">
              {reasonType === "zones" && (
                <ZonesPathologies
                  datas={ZONES_MOCK}
                  selectedData={reason}
                  setSelectedData={setReason}
                />
              )}
              {reasonType === "pathologies" && (
                <ZonesPathologies
                  datas={PATHOLOGIES_MOCK}
                  selectedData={reason}
                  setSelectedData={setReason}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
        <Box flex={1} px="60px" position="relative">
          <TabExercices
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            isCreatingProgram
          />
          {selectedTab === 0 && (
            <FilterExercices
              categories={categories}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              setSearch={setSearch}
              restrictions={restrictions}
              restrictionFilter={restrictionFilter}
              setRestrictionFilter={setRestrictionFilter}
            />
          )}
          <Flex justifyContent="flex-start" flexWrap="wrap" mt={8} gap={4}>
            {selectedTab === 0 &&
              sortedFilteredVideoGroups.map((videoGroup) => (
                <VideoGroup
                  key={`group_${videoGroup.id}`}
                  videoGroup={videoGroup}
                  selectedExercices={selectedExercices}
                  setSelectedExercices={setSelectedExercices}
                />
              ))}
          </Flex>
        </Box>
        {selectedExercices && (
          <SelectedExercices
            selectedExercices={selectedExercices}
            setSelectedExercices={setSelectedExercices}
            isCreatingProgram
          />
        )}
      </Flex>
    </Box>
  )
}

export default SelectProgramExercices
