import { Box, Flex, Heading, Image } from "@chakra-ui/react"
import React from "react"
import corpsFront from "../../../../../../assets/corps-front.svg"
import corpsBack from "../../../../../../assets/corps-back.svg"
import AZonePathologieMarker from "./components/AZonePathologieMarker"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"

interface IProps {
  datas: ZonePathologieType[]
  selectedData: ZonePathologieType | undefined
  setSelectedData: React.Dispatch<
    React.SetStateAction<ZonePathologieType | undefined>
  >
}

const ZonesPathologies: React.FC<IProps> = ({
  datas,
  selectedData,
  setSelectedData,
}) => {
  const handleClick = (currentElt: ZonePathologieType) => {
    if (selectedData && selectedData.id === currentElt.id) {
      setSelectedData(undefined)
    } else {
      setSelectedData(currentElt)
    }
  }

  return (
    <Box mb={75}>
      <Flex gap="33px">
        <Box pos="relative">
          <div>
            <Image
              src={corpsFront}
              alt="Corps de devant"
              userSelect="none"
              w="128px"
              h="393"
            />
            {datas
              .filter((data) => data.schema === 1 || data.secondarySchema === 1)
              .map(({ name, id, left, top, zIndex, code }, i) => (
                <AZonePathologieMarker
                  name={name}
                  code={code}
                  left={left}
                  top={top}
                  key={`front_${id}`}
                  zIndex={zIndex}
                  state={selectedData?.id === id ? "active" : "default"}
                  onClick={() =>
                    handleClick(
                      datas.filter(
                        (data) =>
                          data.schema === 1 || data.secondarySchema === 1
                      )[i]
                    )
                  }
                />
              ))}
          </div>
        </Box>
        <Box pos="relative">
          <Image
            src={corpsBack}
            alt="Corps de derrière"
            userSelect="none"
            w="128px"
            h="393"
          />
          {datas
            .filter((data) => data.schema === 2 || data.secondarySchema === 2)
            .map(({ name, id, left, top, zIndex, code }, i) => (
              <AZonePathologieMarker
                name={name}
                code={code}
                left={left}
                top={top}
                key={`back_${id}`}
                zIndex={zIndex}
                state={selectedData?.id === id ? "active" : "default"}
                onClick={() =>
                  handleClick(
                    datas.filter(
                      (data) => data.schema === 2 || data.secondarySchema === 2
                    )[i]
                  )
                }
              />
            ))}
        </Box>
      </Flex>
    </Box>
  )
}

export default ZonesPathologies
