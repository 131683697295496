import React, { useEffect } from "react"
import { Box, Heading, Select, Text } from "@chakra-ui/react"
import ReactApexChart from "react-apexcharts"
import { PatientType } from "../../../api/patient"
import { ChevronDownIcon } from "@chakra-ui/icons"
import displaySortedCheckups from "utils/displaySortedCheckups"

interface IProps {
  patient?: PatientType
}

const Stats: React.FC<IProps> = ({ patient }) => {
  const [checkups, setCheckups] = React.useState(patient?.checkups || [])

  useEffect(() => {
    setCheckups(patient?.checkups || [])
  }, [patient])

  const series = [
    {
      name: "Douleur",
      data: checkups?.map((checkup) => checkup.pain || 0) || [],
    },
    {
      name: "Fréquence",
      data: checkups?.map((checkup) => checkup.frequency || 0) || [],
    },
    {
      name: "Mobilité",
      data: checkups?.map((checkup) => checkup.mobility || 0) || [],
    },
    {
      name: "Stress",
      data: checkups?.map((checkup) => checkup.stress || 0) || [],
    },
  ]

  const options: any = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "date",
      categories: displaySortedCheckups(checkups),
    },
    colors: ["#A8D8C3", "#F6C36C", "#974837", "#EF8276"],
  }

  const allReasons =
    patient?.checkups?.map((checkup) => checkup.reasons).flat() || []

  const reasonCounts = allReasons.reduce((acc, reason) => {
    acc[reason.id] = (acc[reason.id] || 0) + 1
    return acc
  }, {} as Record<number, number>)

  const reasons = allReasons.filter(
    (reason, index, self) =>
      reasonCounts[reason.id] >= 2 &&
      self.findIndex((r) => r.id === reason.id) === index
  )

  const handleReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") setCheckups(patient?.checkups || [])
    else
      setCheckups(
        patient?.checkups?.filter((checkup) =>
          checkup.reasons.find(
            (reason) => reason.id === parseInt(e.target.value)
          )
        ) || []
      )
  }

  if (checkups.length <= 1) return null
  return (
    <Box
      bg="white"
      pt={6}
      px={4}
      pb={10}
      borderRadius={9}
      mt={6}
      mb={4}
      pos="relative"
    >
      <Heading fontSize={18} mb={4}>
        Évolution de la symptomatologie
      </Heading>
      {checkups.length === 0 ? (
        <Text>Pas de données</Text>
      ) : (
        <div>
          <Select
            placeholder="Motif"
            variant="filled"
            bg="common.100"
            pos="absolute"
            right={4}
            top={4}
            icon={<ChevronDownIcon />}
            width={40}
            color="primary.500"
            fontWeight={700}
            onChange={handleReasonChange}
          >
            {reasons.map((reason) => (
              <option key={reason.id} value={reason.id}>
                {reason.name}
              </option>
            ))}
          </Select>
          <Box height={350} width="100%">
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={350}
            />
          </Box>
        </div>
      )}
    </Box>
  )
}

export default Stats
