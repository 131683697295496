import { ReasonType } from "api/patient"

export interface ZonePathologieType extends ReasonType {
  code: string
  left: string
  top: string
  schema: number
  secondarySchema?: number
  zIndex?: number
  id: number
  type?: "zone" | "pathologie" | undefined
}
export const PATHOLOGIES_MOCK: ZonePathologieType[] = [
  {
    name: "Sciatique",
    code: "SCIATIQUE",
    id: 1,
    left: "30px",
    top: "140px",
    schema: 2,
    type: "pathologie",
  },
  {
    name: "Cruralgie",
    code: "CRURALGIE",
    id: 2,
    schema: 1,
    left: "70px",
    top: "160px",
    zIndex: 10,
    type: "pathologie",
  },
  {
    name: "NCB",
    code: "NCB",
    id: 3,
    schema: 1,
    secondarySchema: 2,
    left: "55px",
    top: "23px",
    zIndex: 5,
    type: "pathologie",
  },
  {
    name: "Pubalgie",
    code: "PUBALGIE",
    id: 4,
    schema: 1,
    left: "51px",
    top: "195px",
    type: "pathologie",
  },
  {
    name: "Tendinite d'épaule",
    code: "TENDINITE_EPAULE",
    id: 5,
    schema: 1,
    left: "5px",
    top: "56px",
    type: "pathologie",
  },
  {
    name: "Pathologie du genou",
    code: "PATHOLOGIE_GENOU",
    id: 6,
    schema: 1,
    left: "28px",
    top: "254px",
    type: "pathologie",
  },
  {
    name: "Entorse cheville",
    code: "ENTORSE_CHEVILLE",
    id: 7,
    schema: 1,
    left: "36px",
    top: "350px",
    type: "pathologie",
  },
  {
    name: "Symptomatologies du pied et de la cheville",
    code: "EPINE_CALCANENE",
    id: 8,
    schema: 1,
    left: "48px",
    top: "368px",
    type: "pathologie",
  },
  {
    name: "Migraine et névralgies d’Arnold",
    code: "MIGRAINE",
    id: 9,
    schema: 1,
    secondarySchema: 2,
    left: "39px",
    top: "-6px",
    type: "pathologie",
  },
  {
    name: "Tendinite interne du coude",
    code: "TENDINITE_INTERNE_COULE",
    id: 10,
    schema: 1,
    left: "15px",
    top: "125px",
    type: "pathologie",
  },
  {
    name: "Tendinite externe du coude",
    code: "TENDINITE_EXTERNE_COULE",
    id: 11,
    schema: 1,
    left: "0px",
    top: "120px",
    type: "pathologie",
  },
  {
    name: "Estomac",
    code: "ESTOMAC",
    id: 12,
    schema: 1,
    left: "65px",
    top: "130px",
    zIndex: 10,
    type: "pathologie",
  },
]

export const ZONES_MOCK: ZonePathologieType[] = [
  {
    name: "Lombaires",
    code: "LOMBALGIE",
    id: 13,
    left: "40px",
    top: "130px",
    schema: 2,
    type: "zone",
  },
  {
    name: "Dorsales",
    code: "DORSALGIE",
    id: 14,
    left: "40px",
    top: "60px",
    schema: 2,
    type: "zone",
  },
  {
    name: "Cervicales",
    code: "CERVICALGIE",
    id: 15,
    left: "52px",
    top: "50px",
    schema: 2,
    type: "zone",
  },
  {
    name: "Hanche",
    code: "HANCHE",
    id: 16,
    left: "18px",
    top: "150px",
    schema: 1,
    type: "zone",
  },
  {
    name: "Genou",
    code: "GENOU",
    id: 17,
    left: "27px",
    top: "255px",
    schema: 1,
    type: "zone",
  },
  {
    name: "Épaules",
    code: "EPAULES",
    id: 18,
    left: "6px",
    top: "55px",
    schema: 1,
    type: "zone",
  },
  {
    name: "Viscéral",
    code: "VISCERAL",
    id: 19,
    left: "39px",
    top: "130px",
    schema: 1,
    type: "zone",
  },
  {
    name: "Crâne",
    code: "CRANE",
    id: 20,
    left: "35px",
    top: "-11px",
    schema: 1,
    secondarySchema: 2,
    type: "zone",
  },
  {
    name: "Sacro iliaque",
    code: "SACRO_ILIAQUE",
    id: 21,
    left: "37px",
    top: "152px",
    schema: 2,
    type: "zone",
  },
  {
    name: "Cheville / Pied",
    code: "CHEVILLE_PIED",
    id: 22,
    left: "27px",
    top: "345px",
    schema: 1,
    type: "zone",
  },
  {
    name: "Poignet",
    code: "POIGNET",
    id: 23,
    left: "-2px",
    top: "170px",
    schema: 1,
    type: "zone",
  },
  {
    name: "Diaphragme",
    code: "DIAPHRAGME",
    id: 24,
    left: "18px",
    top: "103px",
    schema: 1,
    type: "zone",
  },
  {
    name: "Coude",
    code: "COUDE",
    id: 25,
    left: "-2px",
    top: "118px",
    schema: 1,
    type: "zone",
  },
]
