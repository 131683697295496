import React, { useCallback } from "react"
import { Box, Button, Tooltip } from "@chakra-ui/react"
import Cruralgie from "./svg/pathologies/Cruralgie"
import Sciatique from "./svg/pathologies/Sciatique"
import Ncb from "./svg/pathologies/Ncb"
import Pubalgie from "./svg/pathologies/Pubalgie"
import TendiniteEpaule from "./svg/pathologies/TendiniteEpaule"
import PathologieGenou from "./svg/pathologies/PathologieGenou"
import EntorseCheville from "./svg/pathologies/EntorseCheville"
import EpineCalcaneenne from "./svg/pathologies/EpineCalcaneenne"
import Migraine from "./svg/pathologies/Migraine"
import TendiniteCoudeInterne from "./svg/pathologies/TendiniteCoudeInterne"
import TendiniteCoudeExterne from "./svg/pathologies/TendiniteCoudeExterne"
import Estomac from "./svg/pathologies/Estomac"
import Cervicales from "./svg/zones/Cervicales"
import ChevillePied from "./svg/zones/ChevillePied"
import Coude from "./svg/zones/Coude"
import Crane from "./svg/zones/Crane"
import Diaphragme from "./svg/zones/Diaphragme"
import Dorsales from "./svg/zones/Dorsales"
import Epaule from "./svg/zones/Epaule"
import Genoux from "./svg/zones/Genoux"
import Hanches from "./svg/zones/Hanches"
import Lombaires from "./svg/zones/Lombaires"
import Poignet from "./svg/zones/Poignet"
import Viceral from "./svg/zones/Viceral"
import SacroIliaque from "./svg/zones/SacroIliaque"
import BabyBelly from "pages/NewAppointment/components/SelectReason/components/BabyMediaTags/components/svg/BabyBelly"
import BabyHead from "pages/NewAppointment/components/SelectReason/components/BabyMediaTags/components/svg/BabyHead"

interface IProps {
  name: string
  code: string
  state: "default" | "active"
  top: string
  left: string
  onClick: () => void
  zIndex?: number
}

const theme = {
  default: {
    stroke: undefined,
    fill: undefined,
  },
  active: {
    stroke: "#974837",
    fill: "#EF7560",
  },
}

const AZoneMarker: React.FC<IProps> = ({
  name,
  code,
  state,
  left,
  top,
  onClick,
  zIndex = 1,
}) => {
  const getImage = useCallback(() => {
    switch (code) {
      case "SCIATIQUE":
        return <Sciatique {...theme[state]} />
      case "CRURALGIE":
        return <Cruralgie {...theme[state]} />
      case "NCB":
        return <Ncb {...theme[state]} />
      case "PUBALGIE":
        return <Pubalgie {...theme[state]} />
      case `TENDINITE_EPAULE`:
        return <TendiniteEpaule {...theme[state]} />
      case `PATHOLOGIE_GENOU`:
        return <PathologieGenou {...theme[state]} />
      case `ENTORSE_CHEVILLE`:
        return <EntorseCheville {...theme[state]} />
      case `EPINE_CALCANENE`:
        return <EpineCalcaneenne {...theme[state]} />
      case `MIGRAINE`:
        return <Migraine {...theme[state]} />
      case `TENDINITE_INTERNE_COULE`:
        return <TendiniteCoudeInterne {...theme[state]} />
      case `TENDINITE_EXTERNE_COULE`:
        return <TendiniteCoudeExterne {...theme[state]} />
      case `ESTOMAC`:
        return <Estomac {...theme[state]} />
      case `CERVICALGIE`:
        return <Cervicales {...theme[state]} />
      case `CHEVILLE_PIED`:
        return <ChevillePied {...theme[state]} />
      case `COUDE`:
        return <Coude {...theme[state]} />
      case `CRANE`:
        return <Crane {...theme[state]} />
      case `DIAPHRAGME`:
        return <Diaphragme {...theme[state]} />
      case `DORSALGIE`:
        return <Dorsales {...theme[state]} />
      case `EPAULES`:
        return <Epaule {...theme[state]} />
      case `GENOU`:
        return <Genoux {...theme[state]} />
      case `HANCHE`:
        return <Hanches {...theme[state]} />
      case `LOMBALGIE`:
        return <Lombaires {...theme[state]} />
      case `POIGNET`:
        return <Poignet {...theme[state]} />
      case `VISCERAL`:
        return <Viceral {...theme[state]} />
      case `SACRO_ILIAQUE`:
        return <SacroIliaque {...theme[state]} />
      case `TAG_BEBE_VENTRE`:
        return <BabyBelly {...theme[state]} />
      case `TAG_BEBE_TETE`:
        return <BabyHead {...theme[state]} />

      default:
        return null
    }
  }, [state, code])

  return (
    <Tooltip label={name} fontSize="md">
      <Button
        pos="absolute"
        cursor="pointer"
        left={left}
        top={top}
        onClick={onClick}
        zIndex={zIndex}
        aria-label={name}
        padding={0}
        bg="none"
        h="auto"
        w="auto"
        minW={0}
        _hover={{ bg: "none" }}
        transform="scale(0.7)"
      >
        {getImage()}
      </Button>
    </Tooltip>
  )
}

export default AZoneMarker
