import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { useMutation } from "@tanstack/react-query"
import { SelectedExerciceType } from "api/appointmentReason"
import { createProgram } from "api/program"
import AAutocompleteMultiple from "components/AAutocompleteMultiple/AAutocompleteMultiple"
import AButton from "components/AButton"
import {
  PATHOLOGIES_MOCK,
  ZonePathologieType,
  ZONES_MOCK,
} from "pages/NewAppointment/newAppointment.mock"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router-dom"

interface CreateProgramModalProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedExercices: SelectedExerciceType[]
}

const renderItem = ({ name }: ZonePathologieType) => name

const getKey = ({ id }: ZonePathologieType) => id.toString()

const LEVELS = ["Facile", "Moyen", "Difficile"]

const CreateProgramModal: FC<CreateProgramModalProps> = ({
  setIsModalOpen,
  selectedExercices,
}) => {
  const [level, setLevel] = useState(1)
  const [input, setInput] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedItems, setSelectedItems] = useState<ZonePathologieType[]>([])

  const navigate = useNavigate()

  const createProgramMutation = useMutation(createProgram)

  const handleClick = async () => {
    if (selectedExercices && selectedExercices.length > 1 && input !== "") {
      setIsSubmitting(true)
      createProgramMutation.mutate(
        {
          program: {
            appointmentReasons: selectedItems.map(({ id }) => id),
            level,
            title: input,
          },
          videoPrograms: [...selectedExercices],
        },
        {
          onSuccess: () => {
            navigate(`/exercices/`)
            setIsSubmitting(false)
          },
          onError: () => {
            setIsSubmitting(false)
          },
        }
      )
    }
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top="0px"
      left="0px"
      width="full"
      height="100vh"
      bg="rgba(0, 0, 0, 0.4)"
      zIndex={100}
    >
      <Flex
        direction="column"
        w="432px"
        bg="white"
        rounded="8px"
        // overflow="hidden"
      >
        <Box bg="common.300" w="full" h="206px" position="relative">
          {selectedExercices.length > 0 && (
            <Image
              src={selectedExercices[0].preview.formats.small.url}
              h="full"
              w="full"
              objectFit="cover"
            />
          )}
          <Flex
            position="absolute"
            bottom="16px"
            left="16px"
            gap="8px"
            overflowX="scroll"
          >
            {selectedExercices.map(
              (exercice, index) =>
                index !== 0 && (
                  <Image
                    src={exercice.preview.formats.small.url}
                    width="64px"
                    height="40px"
                    objectFit="cover"
                    rounded="4px"
                    border="1px solid"
                    borderColor="white"
                    key={index}
                  />
                )
            )}
          </Flex>
        </Box>
        <Image />
        <Flex direction="column" p="16px" gap="24px">
          {selectedExercices.length > 1 && (
            <>
              <Input
                placeholder="Titre du programme"
                border="none"
                p={0}
                fontSize="18px"
                fontWeight={700}
                _focusVisible={{ border: "none" }}
                onChange={(evt) => setInput(evt.target.value)}
                value={input}
              />
              <Flex direction="column" gap="4px">
                <Text fontSize="14px" fontWeight={700}>
                  Zones / Symptomatologies
                </Text>
                <Flex>
                  <AAutocompleteMultiple
                    bg="white"
                    border="1px solid black"
                    width="100%"
                    placeholder="Sélectionner..."
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    items={[...ZONES_MOCK, ...PATHOLOGIES_MOCK]}
                    renderItem={renderItem}
                    inputValue=""
                    getKey={getKey}
                    isLoading={false}
                  />
                </Flex>
              </Flex>

              <Flex direction="column" gap="4px">
                <Text fontSize="14px" fontWeight={700}>
                  Niveau
                </Text>
                <Menu>
                  <MenuButton
                    w="fit-content"
                    px="16px"
                    py="8px"
                    border="1px solid"
                    rounded="4px"
                  >
                    <Flex alignItems="center" gap="6px">
                      <Text fontSize="14px">{LEVELS[level - 1]}</Text>
                      <ChevronDownIcon boxSize="20px" />
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    {LEVELS.map((currLevel, index) => (
                      <MenuItem
                        key={index + 1}
                        onClick={() => setLevel(index + 1)}
                      >
                        {currLevel}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
            </>
          )}
          {selectedExercices && selectedExercices.length < 2 && (
            <Text>
              Séléctionnez au moins deux exercices pour créer un programme.
            </Text>
          )}
          {selectedItems && selectedItems.length < 1 ? (
            <Text>Séléctionnez au moins une zone ou symptomatologie.</Text>
          ) : input === "" ? (
            <Text>Veuillez choisir un titre</Text>
          ) : (
            ""
          )}

          <Flex justifyContent="end" gap="16px">
            <AButton
              text="Annuler"
              variant="tertiary"
              onClick={() => {
                setIsModalOpen(false)
              }}
            />
            {selectedItems.length > 0 &&
              selectedExercices.length > 1 &&
              input !== "" && (
                <AButton
                  text="Enregistrer"
                  onClick={() => handleClick()}
                  isLoading={isSubmitting}
                />
              )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CreateProgramModal
