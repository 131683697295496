import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Heading, Select, Text } from "@chakra-ui/react"
import React, { useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import displaySortedCheckups from "utils/displaySortedCheckups"
import { PatientAppointmentType, CheckupType } from "../../../api/patient"

interface IProps {
  appointmentCheckups: CheckupType[]
  currentAppointment: PatientAppointmentType
}

const AppointmentStats: React.FC<IProps> = ({
  appointmentCheckups,
  currentAppointment,
}) => {
  const [checkups, setCheckups] = React.useState(appointmentCheckups)

  useEffect(() => {
    setCheckups(appointmentCheckups)
  }, [appointmentCheckups])

  const series = [
    {
      name: "Douleur",
      data: checkups?.map((checkup: CheckupType) => checkup.pain) || [],
    },
    {
      name: "Fréquence",
      data: checkups?.map((checkup) => checkup.frequency) || [],
    },
    {
      name: "Mobilité",
      data: checkups?.map((checkup) => checkup.mobility) || [],
    },
    {
      name: "Stress",
      data: checkups?.map((checkup) => checkup.stress) || [],
    },
  ]

  const options: any = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "date",
      categories: displaySortedCheckups(checkups),
    },
    colors: ["#A8D8C3", "#F6C36C", "#974837", "#EF8276"],
  }

  const allReasons = appointmentCheckups
    ?.map((checkup) => checkup.reasons.map((reason) => reason))
    .flat()

  const reasons = allReasons
    ? allReasons.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      )
    : []

  const handleReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") setCheckups(appointmentCheckups || [])
    else
      setCheckups(
        appointmentCheckups.filter((checkup) =>
          checkup.reasons.find(
            (reason) => reason.id === parseInt(e.target.value)
          )
        ) || []
      )
  }

  if (appointmentCheckups.length <= 1) return null
  if (!currentAppointment) return null
  return (
    <Box mb={6}>
      {appointmentCheckups.length > 0 ? (
        <Box
          height={450}
          bg="white"
          pt={6}
          px={4}
          pb={10}
          borderRadius={9}
          mt={6}
          mb={4}
          pos="relative"
        >
          <Heading as="h3" fontSize={18} mb={5}>
            Évolution de la symptomatologie
          </Heading>
          <Select
            placeholder="Motif"
            variant="filled"
            bg="common.100"
            pos="absolute"
            right={4}
            top={4}
            icon={<ChevronDownIcon />}
            width={40}
            color="primary.500"
            fontWeight={700}
            onChange={handleReasonChange}
          >
            {reasons.map((reason) => (
              <option key={reason.id} value={reason.id}>
                {reason.name}
              </option>
            ))}
          </Select>
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={350}
          />
        </Box>
      ) : (
        <Box mt={6} p={6} bg="white" borderRadius={9}>
          <Heading as="h3" fontSize={18} mb={5}>
            Évolution de la symptomatologie
          </Heading>
          <Text>Pas de données</Text>
        </Box>
      )}
    </Box>
  )
}

export default AppointmentStats
