import { Button, Flex } from "@chakra-ui/react"
import {
  FAVORIS_TAB,
  PROGRAMME_TAB,
  VIDEOS_TAB,
} from "pages/NewAppointment/components/SelectExercices/SelectExercices"
import React, { FC } from "react"

interface TabExercicesProps {
  selectedTab: number
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>
  isPageExercies?: boolean
  isCreatingProgram?: boolean
}

const TabExercices: FC<TabExercicesProps> = ({
  selectedTab,
  setSelectedTab,
  isPageExercies = false,
  isCreatingProgram = false,
}) => {
  return (
    <Flex
      gap="24px"
      top={isPageExercies ? "" : "-64px"}
      position={isPageExercies ? "relative" : "absolute"}
      left={0}
      px={isPageExercies ? "" : "60px"}
    >
      <Button
        p="16px"
        borderBottom="1px solid"
        borderRadius={0}
        textColor={selectedTab === VIDEOS_TAB ? "common.800" : "common.500"}
        bg="none"
        px={0}
        borderColor={selectedTab === VIDEOS_TAB ? "primary.600" : "transparent"}
        onClick={() => setSelectedTab(VIDEOS_TAB)}
        _hover={{ bg: "transparent", opacity: 0.7 }}
      >
        Vidéos
      </Button>
      {!isCreatingProgram && (
        <Button
          p="16px"
          borderBottom="1px solid"
          borderRadius={0}
          textColor={
            selectedTab === PROGRAMME_TAB ? "common.800" : "common.500"
          }
          bg="none"
          px={0}
          borderColor={
            selectedTab === PROGRAMME_TAB ? "primary.600" : "transparent"
          }
          onClick={() => setSelectedTab(PROGRAMME_TAB)}
          _hover={{ bg: "transparent", opacity: 0.7 }}
        >
          Programme
        </Button>
      )}
      <Button
        p="16px"
        borderBottom="1px solid"
        borderRadius={0}
        textColor={selectedTab === FAVORIS_TAB ? "common.800" : "common.500"}
        bg="none"
        px={0}
        borderColor={
          selectedTab === FAVORIS_TAB ? "primary.600" : "transparent"
        }
        onClick={() => setSelectedTab(FAVORIS_TAB)}
        _hover={{ bg: "transparent", opacity: 0.7 }}
      >
        Favoris
      </Button>
    </Flex>
  )
}

export default TabExercices
