import { Button, ButtonProps } from "@chakra-ui/react"
import React, { ElementRef, forwardRef } from "react"

export type VariantType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "quaternary"
  | "destructive"
  | "custom"
// | "redesign"

const theme = {
  primary: {
    bg: "primary.500",
    color: "white",
    borderColor: "primary.500",
    border: "1px solid",
    borderRadius: "52px",
    _hover: {
      bg: "primary.400",
      color: "white",
      borderColor: "primary.400",
    },
    _focus: {
      bg: "primary.400",
      color: "white",
      borderColor: "primary.400",
    },
  },
  secondary: {
    bg: "secondary.600",
    color: "white",
    borderColor: "secondary.600",
    border: "1px solid",
    borderRadius: "52px",
    _hover: {
      bg: "secondary.500",
      color: "white",
      borderColor: "secondary.500",
    },
    _focus: {
      bg: "secondary.500",
      color: "white",
      borderColor: "secondary.500",
    },
  },
  tertiary: {
    bg: "white",
    color: "primary.500",
    borderColor: "primary.500",
    border: "1px solid",
    borderRadius: "52px",

    _hover: {
      bg: "primary.100",
      color: "primary.500",
      borderColor: "primary.500",
    },
    _focus: {
      bg: "primary.100",
      color: "primary.500",
      borderColor: "primary.500",
    },
  },
  quaternary: {
    bg: "white",
    color: "secondary.800",
    borderColor: "secondary.500",
    border: "1px solid",
    borderRadius: "52px",

    _hover: {
      bg: "secondary.100",
      color: "secondary.800",
      borderColor: "secondary.500",
    },
    _focus: {
      bg: "secondary.100",
      color: "secondary.800",
      borderColor: "secondary.500",
    },
  },
  destructive: {
    bg: "danger.500",
    color: "white",
    borderColor: "danger.500",
    border: "1px solid",
    borderRadius: "52px",
    _hover: {
      bg: "danger.400",
      color: "white",
      borderColor: "danger.400",
    },
    _focus: {
      bg: "danger.400",
      color: "white",
      borderColor: "danger.400",
    },
  },
  // redesign: {
  //   bg: "rgba(255, 255, 255, 0.40)",
  //   color: "common.600",
  //   borderRadius: "4px",
  //   justify: "start",
  //   _hover: {
  //     bg: "rgba(255, 255, 255, 0.70)",
  //     color: "common.600",
  //   },
  //   _focus: {
  //     bg: "rgba(255, 255, 255, 0.70)",
  //     color: "white",
  //   },
  // },
}

const getTheme = (variant: VariantType) => {
  if (variant === "custom") return {}

  return theme[variant]
}

interface IProps extends ButtonProps {
  variant?: VariantType | "custom"
  text: string
}

const AButton = forwardRef<ElementRef<typeof Button>, IProps>(
  ({ variant = "primary", text, ...props }, ref) => {
    return (
      <Button ref={ref} {...props} {...getTheme(variant)}>
        <span>{text}</span>
      </Button>
    )
  }
)

AButton.displayName = "AButton"

export default AButton
