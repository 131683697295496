import { Box, Divider, FormLabel, Textarea } from "@chakra-ui/react"
import React, { useId } from "react"

interface IProps {
  advice: string | null
  setAdvice: React.Dispatch<React.SetStateAction<string | null>>
}

const PatientAdvice: React.FC<IProps> = ({ advice, setAdvice }) => {
  const textareaId = useId()
  return (
    <Box mb={12}>
      <Box mb={12}>
        <FormLabel
          fontFamily="Montserrat"
          fontWeight="bold"
          htmlFor={textareaId}
          fontSize={16}
          mb={4}
        >
          Recommandation pour le patient
        </FormLabel>
        <Textarea
          id={textareaId}
          placeholder="Recommandation pour le patient"
          bg="white"
          border="0px"
          w="full"
          fontSize={16}
          p="12px"
          minH="120px"
          value={advice || ""}
          onChange={(e) => setAdvice(e.target.value)}
        />
      </Box>
    </Box>
  )
}

export default PatientAdvice
