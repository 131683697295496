import { Flex, Heading, Text } from "@chakra-ui/react"
import { PersonalStatsType } from "api/stats"
import Chart from "./Chart"
import SingleData from "./SingleData"
import getMonthsAbrev from "utils/chart/getMonthsAbrev"
import getChartOptions from "utils/chart/getChartOptions"
import getChartData from "utils/chart/getChartData"

interface PersonalStatsProps {
  personalStats: PersonalStatsType | undefined
}

const PersonalStats = ({ personalStats }: PersonalStatsProps) => {
  if (!personalStats) {
    return <div>Aucune donnée</div>
  }

  const monthAbreviationsAppointmentsForMe = getMonthsAbrev(
    personalStats.averageAppointmentsCountByMonthByOsteo || []
  )

  const appointmentsByMonthForMeData = getChartData(
    personalStats.osteoAppointmentsCountByMonth,
    ["Mes consultations"]
  )

  const optionsAppointmentsByMonthForMe = getChartOptions(
    monthAbreviationsAppointmentsForMe,
    ["#A8D8C3"]
  )

  const otherDataWillComeLabel =
    "D'autres statistiques\npersonnelles arriveront dans\nquelques semaines"

  return (
    <Flex direction={"column"} wrap={"wrap"} maxW={1100} w={"full"}>
      <Heading as="h2" mb={8} fontSize={24} color="tertiary.500">
        Statistiques personnelles
      </Heading>
      <Flex gap={6} alignItems={"stretch"} wrap="wrap">
        <Flex bg={"white"} borderRadius={9}>
          <Chart
            title="Nombre de consultations"
            options={optionsAppointmentsByMonthForMe}
            series={appointmentsByMonthForMeData}
            chartWidth={652}
            showTotalData={true}
            labelColor={"secondary.600"}
          />
        </Flex>
        <SingleData
          value={personalStats.ourActivePatientsCount}
          label={"Part de vos patients actifs sur\nAndrew"}
          unit={"%"}
          sublabel={
            "Continuez à engager vos patients,\nà les sensibiliser à l'utilisation de\nl'application, ils seront plus actifs."
          }
          labelColor={"secondary.600"}
        />
        <SingleData
          value={personalStats.ourPatientCheckupCount}
          label={"Nombre de questionnaires de santé\nde vos patients"}
          labelColor={"secondary.600"}
        />
        <Flex
          p={6}
          borderRadius={"2xl"}
          bg={"tertiary.200"}
          w={345}
          justify={"center"}
          align={"center"}
          h={122}
        >
          <Text
            whiteSpace="pre-wrap"
            dangerouslySetInnerHTML={{ __html: otherDataWillComeLabel }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PersonalStats
