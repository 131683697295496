import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import React from "react"
import ASlider from "./components/ASlider"

interface IProps {
  pain: number | undefined
  setPain: React.Dispatch<React.SetStateAction<number | undefined>>
  stress: number | undefined
  setStress: React.Dispatch<React.SetStateAction<number | undefined>>
  frequency: number | undefined
  setFrequency: React.Dispatch<React.SetStateAction<number | undefined>>
  mobility: number | undefined
  setMobility: React.Dispatch<React.SetStateAction<number | undefined>>
}

const Questionnaire: React.FC<IProps> = ({
  pain,
  setPain,
  stress,
  setStress,
  frequency,
  setFrequency,
  mobility,
  setMobility,
}) => {
  return (
    <Flex alignItems="center" gap={24} w="full">
      <Flex direction="column" gap="16px">
        <Heading fontSize={16}>Questionnaire santé</Heading>
        <ASlider
          label="Douleur"
          subtitle={`Comment jugeriez-vous l'intensité de vos douleurs ?`}
          value={pain}
          handleChange={setPain}
        />
        <ASlider
          label="Fréquence"
          subtitle={`Comment qualifierez-vous la fréquence de vos douleurs ?`}
          value={frequency}
          handleChange={setFrequency}
        />
        <ASlider
          label="Mobilité"
          subtitle={`Vous sentez vous limité.e dans vos mouvements ?`}
          value={mobility}
          handleChange={setMobility}
        />
        <ASlider
          label="Stress"
          subtitle={`Comment évaluez-vous votre niveau de stress ?`}
          value={stress}
          handleChange={setStress}
        />
      </Flex>
    </Flex>
  )
}

export default Questionnaire
