import { InfoOutlineIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import { MeType } from "api/user"
import AButton from "components/AButton"
import { ReminderDataType } from "pages/Profile/Profile"
import React, { useState } from "react"

interface IProps {
  reminderData: ReminderDataType
  setReminderData: React.Dispatch<React.SetStateAction<ReminderDataType>>
}

const DELAYS = ["30", "60", "90", "182", "365"]

const ReminderSettings: React.FC<IProps> = ({
  reminderData,
  setReminderData,
}) => {
  return (
    <Flex
      direction="column"
      flex={1}
      bg="white"
      borderRadius={9}
      p={8}
      mr={6}
      gap="16px"
    >
      <Flex justifyContent="space-between">
        <Heading fontSize="22px">Rappel de consultation</Heading>
        <Switch
          as="div"
          whiteSpace="nowrap"
          colorScheme="orange"
          isChecked={reminderData.isReminderActivated}
          onChange={() =>
            setReminderData((prev) => ({
              ...prev,
              isReminderActivated: !prev.isReminderActivated,
            }))
          }
          height="fit-content"
          py="4px"
          display="flex"
          alignItems="center"
          borderRadius="xl"
          fontWeight={"bold"}
          w="fit-content"
        >
          Activé
        </Switch>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <InfoOutlineIcon />
        <Text>
          Définissez la notification qui sera envoyé à vos patients pour leur
          rappeler de consulter à nouveau en cas de besoin.
        </Text>
      </Flex>
      {reminderData.isReminderActivated && (
        <Flex gap="16px">
          <Flex direction="column" w="full">
            <FormLabel fontWeight={700}>Texte (max 128 caractères)</FormLabel>
            <Textarea
              bg="common.100"
              placeholder="Votre message"
              height="223px"
              onChange={(evt) =>
                setReminderData((prev) => ({
                  ...prev,
                  reminderText: evt.target.value,
                }))
              }
              value={reminderData.reminderText}
            />
          </Flex>
          <Flex direction="column" w="30%">
            <FormLabel fontWeight={700}>Délai d’envoi</FormLabel>
            <Select
              bg="common.100"
              defaultValue={
                reminderData.reminderDelay &&
                DELAYS.includes(`${reminderData.reminderDelay}`)
                  ? reminderData.reminderDelay
                  : DELAYS[3]
              }
              onChange={(evt) =>
                setReminderData((prev) => ({
                  ...prev,
                  reminderDelay: parseInt(evt.target.value),
                }))
              }
            >
              <option value={DELAYS[0]}>1 mois</option>
              <option value={DELAYS[1]}>2 mois</option>
              <option value={DELAYS[2]}>3 mois</option>
              <option value={DELAYS[3]}>6 mois</option>
              <option value={DELAYS[4]}>12 mois</option>
            </Select>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default ReminderSettings
